import React from "react";
import styled from "styled-components";
const Footer = ({ pos }) => {
  const date = new Date();

  return <Rights pos={pos}>all rights reserved {date.getFullYear()}</Rights>;
};
const Rights = styled.span`
  font-size: 12px;
  position: ${(props) => props.pos};
  bottom: 40px;
  left: 40px;
  color: var(--grey);
`;
export default Footer;
